import(/* webpackMode: "eager" */ "/builds/zbir-project/zbir-monorepo/ci-build/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/zbir-project/zbir-monorepo/ci-build/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/builds/zbir-project/zbir-monorepo/ci-build/node_modules/next/font/google/target.css?{\"path\":\"src/app/(base)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/builds/zbir-project/zbir-monorepo/ci-build/apps/auth/src/app/(base)/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/zbir-project/zbir-monorepo/ci-build/packages/announcement/src/bar.tsx");
;
import(/* webpackMode: "eager" */ "/builds/zbir-project/zbir-monorepo/ci-build/packages/ui/src/images/logo-simple.png");
